.button {
    border-radius: 2px;
    color: currentColor;
    display: inline-block;
    font-family: $default-font-family;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: calc(22/12);
    padding: $space $space-2;
    text-decoration: none;

    &_primary {
        background-color: $denim;
        color: $white;

        &:hover,
        &:focus {
            animation: none;
            background-color: $azure-radiance;
        }
    }

    &_secondary {
        background-color: $alto;
        color: $blitz;

        &:hover,
        &:focus {
            animation: none;
            background-color: $silver;
        }

        &_alt {
            background-color: $tundora;
            color: $white;

            &:hover,
            &:focus {
                animation: none;
                background-color: $dusty-gray;
            }
        }
    }

    &--wide {
        padding-left: $space-6;
        padding-right: $space-6;
    }

    &--full-width {
        text-align: center;
        width: 100%;
        cursor: pointer;
    }

    &--full-width-small-only {
        @include media($mobile-only) {
            text-align: center;
            width: 100%;
        }
    }
}

.link {
    color: currentColor;
    text-decoration: none;

    &_primary {
        font-family: $secondary-font-name;
        font-size: 18px;
        padding-left: $space-2;
        position: relative;

        &:before {
            color: $azure-radiance;
            content: $icon-caret-right;
            display: inline-block;
            font-family: $icomoon-font-family;
            font-size: 6px;
            left: 0;
            margin-top: 0;
            position: absolute;
            right: auto;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_secondary {
        padding-left: $space-2;
        position: relative;

        &:before {
            color: $azure-radiance;
            content: $icon-caret-right;
            display: inline-block;
            font-family: $icomoon-font-family;
            font-size: 6px;
            left: 0;
            margin-top: 0;
            position: absolute;
            right: auto;
            top: 6px;
            transform: translateY(-50%);
        }

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_plain-link {
        color: $denim;

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_in-text {
        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_bold {
        font-size: 14px;
        font-weight: $font-weight-bold;

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_bold_sub {
        font-size: 12px;
        font-weight: $font-weight-bold;

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }

    &_icon_text {
        @include section-reg;

        [class^="icon-mb"], [class*=" icon-mb"] {
            vertical-align: middle;
        }

        &:hover,
        &:focus {
            animation: none;
            color: $azure-radiance;
        }
    }
}

.hidden {
    display: none;
}
$icomoon-font-family: "micro-icons";
$icomoon-font-path: $fonts-path !default;

$icon-warning: "\e94e";
$icon-checkmark-disc: "\e94d";
$icon-cross: "\e94b";
$icon-pencil: "\e94a";
$icon-logout: "\e949";
$icon-movie: "\e948";
$icon-pdf: "\e947";
$icon-web-link: "\e946";
$icon-barcode: "\e940";
$icon-call: "\e941";
$icon-chat: "\e942";
$icon-list-menu: "\e943";
$icon-envelope: "\e944";
$icon-profile: "\e945";
$icon-list-alt: "\e935";
$icon-caret-down-small: "\e93d";
$icon-dark-printer: "\e93e";
$icon-rate-star-button: "\e93f";
$icon-sort-descending: "\e93a";
$icon-sort-down: "\e93b";
$icon-descendant: "\e93c";
$icon-chained-links: "\e938";
$icon-world: "\e939";
$icon-forward-right-arrow-button: "\e937";
$icon-paperclip: "\e936";
$icon-check: "\e934";
$icon-warning: "\e932";
$icon-mb-dealer-location: "\e92d";
$icon-mb-collision-center: "\e92f";
$icon-AMG_logo-performance-center: "\e930";
$icon-AMG_logo-performance-center-elite: "\e931";
$icon-search: "\e92c";
$icon-car-rear: "\e92e";
$icon-caret-right-amg: "\e92b";
$icon-acceleration: "\e900";
$icon-arrow-down-square: "\e913";
$icon-arrow-left-square: "\e915";
$icon-arrow-right-square: "\e91a";
$icon-arrow-up-square: "\e91b";
$icon-calculator: "\e91c";
$icon-check-badge: "\ea24";
$icon-download-arrow: "\e91d";
$icon-exit: "\e91e";
$icon-gears: "\e91f";
$icon-horsepower: "\e920";
$icon-hybrid: "\e921";
$icon-minus: "\e922";
$icon-plus: "\e923";
$icon-rain: "\e924";
$icon-request_a_test_drive: "\e925";
$icon-solid-circle: "\e926";
$icon-steering-wheel: "\e927";
$icon-sun: "\e928";
$icon-torque: "\e929";
$icon-towing: "\e92a";
$icon-compare: "\e916";
$icon-download: "\e917";
$icon-special-offers: "\e918";
$icon-view-inventory: "\e919";
$icon-trade_in_value: "\e901";
$icon-apply_for_credit: "\e912";
$icon-model-chooser: "\e914";
$icon-caret-down-thin: "\e902";
$icon-caret-down: "\e903";
$icon-caret-left-thin: "\e904";
$icon-caret-right-thin: "\e905";
$icon-caret-right: "\e906";
$icon-caret-up-thin: "\e907";
$icon-caret-up: "\e908";
$icon-mbusa-logo: "\e909";
$icon-pin: "\e90a";
$icon-social-facebook: "\e90b";
$icon-social-googleplus: "\e90c";
$icon-social-instagram: "\e90d";
$icon-social-twitter: "\e90e";
$icon-social-youtube: "\e90f";
$icon-AMG_logo: "\e910";
$icon-smart: "\e911";
$icon-preferred-dealer: "\e933";
$icon-calendar: "\e94c";

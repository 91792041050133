$font-space-2: 2px;
$font-space-4: 4px;
$font-space-8: 8px;
$font-space-12: 12px;
$font-space-16: 16px;
$font-space-24: 24px;
$font-space-32: 32px;
$font-space-40: 40px;
$font-space-48: 48px;
$font-space-64: 64px;
$font-space-80: 80px;
$font-space-104: 104px;

$font-weight: (
    'font-weight-regular': 400,
    'font-weight-bold': 700
);
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-families: (
    'font-family-10': (MBCorpoA, Georgia, serif),
    'font-family-20': (MBCorpoS, Arial, sans-serif)
);
$font-family-10: (MBCorpoA, Georgia, serif);
$font-family-20: (MBCorpoS, Arial, sans-serif);
$line-height-increment-10: $font-space-8;


/**
 * Do not edit directly
 * Generated on Mon, 13 Dec 2021 16:16:12 GMT
 *
 * do not delete 9fbef606107a605d69c0edbcd8029e5d
 */

$font-10-24-font-family: $font-family-10 !default;
$font-10-24-font-weight: $font-weight-regular !default;
$font-10-24-font-size: $font-space-24 !default;
$font-10-24-line-height: $line-height-increment-10 !default;
$font-10-32-font-family: $font-family-10 !default;
$font-10-32-font-weight: $font-weight-regular !default;
$font-10-32-font-size: $font-space-32 !default;
$font-10-32-line-height: $line-height-increment-10 !default;
$font-10-40-font-family: $font-family-10 !default;
$font-10-40-font-weight: $font-weight-regular !default;
$font-10-40-font-size: $font-space-40 !default;
$font-10-40-line-height: $line-height-increment-10 !default;
$font-10-48-font-family: $font-family-10 !default;
$font-10-48-font-weight: $font-weight-regular !default;
$font-10-48-font-size: $font-space-48 !default;
$font-10-48-line-height: $line-height-increment-10 !default;
$font-10-64-font-family: $font-family-10 !default;
$font-10-64-font-weight: $font-weight-regular !default;
$font-10-64-font-size: $font-space-64 !default;
$font-10-64-line-height: $line-height-increment-10 !default;
$font-10-80-font-family: $font-family-10 !default;
$font-10-80-font-weight: $font-weight-regular !default;
$font-10-80-font-size: $font-space-80 !default;
$font-10-80-line-height: $line-height-increment-10 !default;
$font-10-104-font-family: $font-family-10 !default;
$font-10-104-font-weight: $font-weight-regular !default;
$font-10-104-font-size: $font-space-104 !default;
$font-10-104-line-height: $line-height-increment-10 !default;
$font-20-12-font-family: $font-family-20 !default;
$font-20-12-font-weight: $font-weight-regular !default;
$font-20-12-font-size: $font-space-12 !default;
$font-20-12-line-height: $line-height-increment-10 !default;
$font-20-16-font-family: $font-family-20 !default;
$font-20-16-font-weight: $font-weight-regular !default;
$font-20-16-font-size: $font-space-16 !default;
$font-20-16-line-height: $line-height-increment-10 !default;
$font-20-24-font-family: $font-family-20 !default;
$font-20-24-font-weight: $font-weight-regular !default;
$font-20-24-font-size: $font-space-24 !default;
$font-20-24-line-height: $line-height-increment-10 !default;
$font-20-32-font-family: $font-family-20 !default;
$font-20-32-font-weight: $font-weight-regular !default;
$font-20-32-font-size: $font-space-32 !default;
$font-20-32-line-height: $line-height-increment-10 !default;
$font-20-40-font-family: $font-family-20 !default;
$font-20-40-font-weight: $font-weight-regular !default;
$font-20-40-font-size: $font-space-40 !default;
$font-20-40-line-height: $line-height-increment-10 !default;
$font-20-12-bold-font-family: $font-family-20 !default;
$font-20-12-bold-font-weight: $font-weight-bold !default;
$font-20-12-bold-font-size: $font-space-12 !default;
$font-20-12-bold-line-height: $line-height-increment-10 !default;
$font-20-16-bold-font-family: $font-family-20 !default;
$font-20-16-bold-font-weight: $font-weight-bold !default;
$font-20-16-bold-font-size: $font-space-16 !default;
$font-20-16-bold-line-height: $line-height-increment-10 !default;
$font-20-24-bold-font-family: $font-family-20 !default;
$font-20-24-bold-font-weight: $font-weight-bold !default;
$font-20-24-bold-font-size: $font-space-24 !default;
$font-20-24-bold-line-height: $line-height-increment-10 !default;
$font-20-32-bold-font-family: $font-family-20 !default;
$font-20-32-bold-font-weight: $font-weight-bold !default;
$font-20-32-bold-font-size: $font-space-32 !default;
$font-20-32-bold-line-height: $line-height-increment-10 !default;
$font-20-40-bold-font-family: $font-family-20 !default;
$font-20-40-bold-font-weight: $font-weight-bold !default;
$font-20-40-bold-font-size: $font-space-40 !default;
$font-20-40-bold-line-height: $line-height-increment-10 !default;

$typestyle: (
    'typestyle': (
        'font-10-24': (
            'font-family': $font-10-24-font-family,
            'font-weight': $font-10-24-font-weight,
            'font-size': $font-10-24-font-size,
            'line-height': $font-10-24-line-height
        ),
        'font-10-32': (
            'font-family': $font-10-32-font-family,
            'font-weight': $font-10-32-font-weight,
            'font-size': $font-10-32-font-size,
            'line-height': $font-10-32-line-height
        ),
        'font-10-40': (
            'font-family': $font-10-40-font-family,
            'font-weight': $font-10-40-font-weight,
            'font-size': $font-10-40-font-size,
            'line-height': $font-10-40-line-height
        ),
        'font-10-48': (
            'font-family': $font-10-48-font-family,
            'font-weight': $font-10-48-font-weight,
            'font-size': $font-10-48-font-size,
            'line-height': $font-10-48-line-height
        ),
        'font-10-64': (
            'font-family': $font-10-64-font-family,
            'font-weight': $font-10-64-font-weight,
            'font-size': $font-10-64-font-size,
            'line-height': $font-10-64-line-height
        ),
        'font-10-80': (
            'font-family': $font-10-80-font-family,
            'font-weight': $font-10-80-font-weight,
            'font-size': $font-10-80-font-size,
            'line-height': $font-10-80-line-height
        ),
        'font-10-104': (
            'font-family': $font-10-104-font-family,
            'font-weight': $font-10-104-font-weight,
            'font-size': $font-10-104-font-size,
            'line-height': $font-10-104-line-height
        ),
        'font-20-12': (
            'font-family': $font-20-12-font-family,
            'font-weight': $font-20-12-font-weight,
            'font-size': $font-20-12-font-size,
            'line-height': $font-20-12-line-height
        ),
        'font-20-16': (
            'font-family': $font-20-16-font-family,
            'font-weight': $font-20-16-font-weight,
            'font-size': $font-20-16-font-size,
            'line-height': $font-20-16-line-height
        ),
        'font-20-24': (
            'font-family': $font-20-24-font-family,
            'font-weight': $font-20-24-font-weight,
            'font-size': $font-20-24-font-size,
            'line-height': $font-20-24-line-height
        ),
        'font-20-32': (
            'font-family': $font-20-32-font-family,
            'font-weight': $font-20-32-font-weight,
            'font-size': $font-20-32-font-size,
            'line-height': $font-20-32-line-height
        ),
        'font-20-40': (
            'font-family': $font-20-40-font-family,
            'font-weight': $font-20-40-font-weight,
            'font-size': $font-20-40-font-size,
            'line-height': $font-20-40-line-height
        ),
        'font-20-12-bold': (
            'font-family': $font-20-12-bold-font-family,
            'font-weight': $font-20-12-bold-font-weight,
            'font-size': $font-20-12-bold-font-size,
            'line-height': $font-20-12-bold-line-height
        ),
        'font-20-16-bold': (
            'font-family': $font-20-16-bold-font-family,
            'font-weight': $font-20-16-bold-font-weight,
            'font-size': $font-20-16-bold-font-size,
            'line-height': $font-20-16-bold-line-height
        ),
        'font-20-24-bold': (
            'font-family': $font-20-24-bold-font-family,
            'font-weight': $font-20-24-bold-font-weight,
            'font-size': $font-20-24-bold-font-size,
            'line-height': $font-20-24-bold-line-height
        ),
        'font-20-32-bold': (
            'font-family': $font-20-32-bold-font-family,
            'font-weight': $font-20-32-bold-font-weight,
            'font-size': $font-20-32-bold-font-size,
            'line-height': $font-20-32-bold-line-height
        ),
        'font-20-40-bold': (
            'font-family': $font-20-40-bold-font-family,
            'font-weight': $font-20-40-bold-font-weight,
            'font-size': $font-20-40-bold-font-size,
            'line-height': $font-20-40-bold-line-height
        )
    )
);

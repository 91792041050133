/**
 map-deep-get($map, $keys...)

 Get deeply nested map value, like map-get but for deeply nested maps
 @param {Map} $map
 @param {String} $keys variable number of key args
 @return {*} The value
*/
@function map-deep-get($map, $keys...) {
    $value: $map;
    @each $key in $keys {
        $value: map-get($value, $key);
    }
    @return $value;
}


/**
 font($name) (i.e. - typestyles)
 Mixin for applying the font (sets font-family, font-size, font-weight, & line-height)
 @param {String} $name Name of the font
*/
@mixin font($name) {
    $parsed-name: 'font-' + $name;
    $font-size: map-deep-get($typestyle, 'typestyle', $parsed-name, 'font-size');
    $line-height-increment: map-deep-get($typestyle, 'typestyle', $parsed-name, 'line-height');

    font-family: map-deep-get($typestyle, 'typestyle', $parsed-name, 'font-family');
    font-size: $font-size;
    font-weight: map-deep-get($typestyle, 'typestyle', $parsed-name, 'font-weight');
    line-height: calc(#{$font-size} + #{$line-height-increment});
}

// do not delete 9fbef606107a605d69c0edbcd8029e5d

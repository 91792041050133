@mixin center-content($direction) {
    position: absolute;

    @if($direction == horizontal) {
        left: 50%;
        transform: translateX(-50%);
    } @else if($direction == vertical) {
        top: 50%;
        transform: translateY(-50%);
    } @else if ($direction == both) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin img-responsive {
    @extend %img-responsive;
}

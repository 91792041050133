.title-hero {
    padding-top: $space-12;

    &__title {
        @include main-heading;
        overflow-wrap: break-word;
        text-align: center;

        @include media($tablet) {
            @include span-columns(8);
            @include shift(2);
        }
    }

    &__sub-heading {
        margin: $space-2 auto 0;
        overflow-wrap: break-word;
        text-align: center;

        @include media($tablet) {
            @include span-columns(8);
            @include shift(2);
        }
    }
}
// do not delete 9fbef606107a605d69c0edbcd8029e5d

//http://chir.ag/projects/name-that-color
//http://www.color-blindness.com/color-name-hue/ - if you get duplicates

$pacific-blue: #00A6C9; //grid overlay
$white: #fff;
$white-sand: #f6f6f6;
$seashell: #f1f1f1;
$mercury: #e3e3e3;
$alto: #dddddd;
$silver: #c7c7c7;
$silver-light:#C0C0C0;
$very-light-grey: #cccccc;
$silver-chalice: #a3a3a3;
$dusty-gray: #999;
$dove-gray: #666;
$scorpion: #5a5a5a;
$tundora: #444;
$mine-shaft: #333;
$night-rider: #313131;
$asphalt: #2a2a2a;
$blitz: #222;
$black: #000000;
$azure-radiance: #0090ff;
$denim: #176db7;
$cinnabar: #e23a39;
$guardsman-red: #cc0000;
$fruit-salad: #569C53;
$cerulean: #00AEDF;
$shark: #2F3033;
$athens-gray: #F7F7F9;
$mischka: #e1e1e8;
$emperor: #555555;
$boston-blue: #428bca;
$cod-gray: #141414;
$deep-blue-sky: #00ADEF;
$gallery:#eeeeee;
$gray:#7F7F7F;
$boulder: #777777;
$charcoal: #424242;
$wild-sand: #F5F5F5;
$astral: #3276B1;
$st-tropaz: #285E8E;
$jelly-bean: #2D6CA2;
$international-klein-blue: #0431B4;
$dodger-blue: #1E8DF4;
$alabaster: #f8f8f8;
$viking: #5bc0de;
$scooter: #39B3D7;
$cornflower-blue: #66AFE9;
$dark-gray: #A1A1A1;
$white-smoke: #F0F0F0;
$monza: #D0011B;
$concrete: #F2F2F2;
$bay-of-many: #23527C;
$havelock-blue: #4990E2;
$bizarre: #F2DEDE;
$apple-blossom: #A94442;
$killarney: #2B542C;
$fern: #67B168;

//color added to resolve contrast error.
$blue-ribbon: #0071EF;
$blue-light: #0051C2;

$color-neutral-10: #ffffff;
$color-neutral-20: #f1f1f1;
$color-neutral-30: #e3e3e3;
$color-neutral-40: #c6c6c6;
$color-neutral-50: #999999;
$color-neutral-60: #717171;
$color-neutral-70: #393939;
$color-neutral-80: #000000;

$color-utility-10: #eb0000;
$color-utility-20: #41853d;
$color-utility-30: #ebe22a;

$color-mbusa-primary-10: #176db7;
$color-mbusa-secondary-10: #e8f1f8;
// color palette variables
$palette-colors: (
    'white': $white,
    'white-sand': $white-sand,
    'seashell': $seashell,
    'mercury': $mercury,
    'alto': $alto,
    'silver': $silver,
    'silver-chalice': $silver-chalice,
    'dusty-gray': $dusty-gray,
    'dove-gray': $dove-gray,
    'scorpion': $scorpion,
    'tundora': $tundora,
    'mine-shaft': $mine-shaft,
    'night-rider': $night-rider,
    'asphalt': $asphalt,
    'blitz': $blitz,
    'black': $black,
    'azure-radiance': $azure-radiance,
    'denim': $denim,
    'cinnabar': $cinnabar,
    'guardsman-red': $guardsman-red,
    'fruit-salad': $fruit-salad,
    'cerulean': $cerulean,
    'shark': $shark,
    'athens-gray': $athens-gray,
    'mischka': $mischka,
    'emperor': $emperor,
    'boston-blue': $boston-blue,
    'cod-gray': $cod-gray,
    'deep-blue-sky': $deep-blue-sky,
    'very-light-grey': $very-light-grey,
    'gallery': $gallery,
    'gray': $gray,
    'boulder': $boulder,
    'charcoal': $charcoal,
    'wild-sand': $wild-sand,
    'astral': $astral,
    'st-tropaz':$st-tropaz,
    'jelly-bean': $jelly-bean,
    'international-klein-blue': $international-klein-blue,
    'dodger-blue': $dodger-blue,
    'alabaster': $alabaster,
    'viking': $viking,
    'scooter': $scooter,
    'cornflower-blue': $cornflower-blue,
    'dark-gray': $dark-gray,
    'white-smoke': $white-smoke,
    'monza': $monza,
    'concrete': $concrete,
    'bay-of-many': $bay-of-many,
    'havelock-blue': $havelock-blue,
    'blue-ribbon': $blue-ribbon,
    'blue-light': $blue-light,
    'bizarre': $bizarre,
    'apple-blossom': $apple-blossom,
    'killarney':$killarney,
    'fern': $fern
);

@function color($color-name) {
    @return map-get($palette-colors, $color-name);
}

$space: 5px;
$space-2: $space*2;
$space-3: $space*3;
$space-4: $space*4;
$space-5: $space*5;
$space-6: $space*6;
$space-7: $space*7;
$space-8: $space*8;
$space-9: $space*9;
$space-10: $space*10;
$space-12: $space*12;
$space-13: $space*13;
$space-14: $space*14;
$space-15: $space*15;
$space-16: $space*16;
$space-17: $space*17;
$space-18: $space*18;
$space-20: $space*20;
$space-28: $space*28;
$space-32: $space*32;
$space-33: $space*33;
$space-34: $space*34;
$space-35: $space*35;
$space-36: $space*36;
$space-37: $space*37;
$space-38: $space*38;
$space-39: $space*39;
$space-40: $space*40;
$space-42: $space*42;
$space-43: $space*43;
$space-44: $space*44;
$space-45: $space*45;
$space-46: $space*46;
$space-47: $space*47;
$space-48: $space*48;
$space-50: $space*50;
$space-51: $space*51;
$space-52: $space*52;
$space-54: $space*54;
$space-55: $space*55;
$space-56: $space*56;
$space-57: $space*57;
$space-60: $space*60;
$space-62: $space*62;
$space-64: $space*64;
$space-65: $space*65;
$space-66: $space*66;
$space-68: $space*68;
$space-70: $space*70;
$space-72: $space*72;
$space-74: $space*74;
$space-75: $space*75;
$space-78: $space*78;
$space-82: $space*82;
$space-84: $space*84;
$space-85: $space*85;
$space-87: $space*87;
$space-90: $space*90;
$space-92: $space*92;
$space-93: $space*93;
$space-96: $space*96;